.small-margin-top {
    margin-top: 1rem;
}

.no-opacity {
    opacity: 0;
}

.shadow-under {
    box-shadow: .6rem 2rem 2rem .5rem rgba(0, 0, 0, 0.9);
}

.svg-shadow {
    filter: drop-shadow(1px 1.5px 2px black);
}