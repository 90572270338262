/*
  TODO: Fix modal sizing to be larger
*/

:root {
  --cool-red: #ea1508;
  --cool-red-light: #8c302b;
  --secondary-color: #b0e4e4;
  --off-white: #f0f0f0;
  --my-grey: rgb(164, 149, 149);
  --text-theme: white;
}

/* ---------------------- Image Gallery ---------------------- */

.image-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  /* grid-gap: 1.5rem; */
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  /* position: relative; */
  /* Establishes a positional context for the absolutely positioned child element */
}

.image-gallery a {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* puts in front of modal-background */

  height: 25rem;

  overflow: hidden;

  text-decoration: none;
  box-shadow: 0rem 0px 6rem 0.8rem rgba(0, 0, 0, 0.7);
}

.image-gallery a img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.image-gallery a svg {
  position: absolute; /* This allows us to use the z-index, which will let us sit our icon on top of our image */

  top: 1rem; /* Distance from the top of the container */
  right: 1rem; /* Distance from the right of the container */
  z-index: 100;

  width: 6.75rem;
  padding: 0.5rem 1.75rem;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 0.4rem;

  font-size: 2.5rem;
  color: rgba(255, 255, 255, 0.6);

  opacity: 0;
  transition: opacity 0.5s;
}

.image-gallery a .is-mobile svg {
  opacity: 0.6;
}

.image-gallery a::before {
  content: "";

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  opacity: 0.1;
  transition: opacity 0.5s;
}

.image-gallery a:hover svg,
.image-gallery a:hover::before {
  cursor: pointer;
  opacity: 0.8;
}

.blur-load {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;

  background-size: cover;
  background-position: center;
}

.blur-load.loaded > img {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.blur-load > img {
  opacity: 0;
}

/* ---------------------- End Image Gallery ---------------------- */

/* ---------------------- TatsGallery ------------------------- */

.tats-gallery {
  grid-template-areas:
    "img-33 img-29 img-29 img-34"
    "img-31 img-31 img-27 img-30"
    "img-23 img-23 img-27 img-28"
    "img-23 img-23 img-24 img-28"
    "img-17 img-2 img-25 img-25"
    "img-1 img-2 img-3 img-3"
    "img-1 img-4 img-5 img-9"
    "img-7 img-7 img-8 img-9"
    "img-6 img-10 img-10 img-10 "
    "img-6 img-11 img-11 img-26"
    "img-12 img-13 img-15 img-14"
    "img-16 img-18 img-32 img-14"
    "img-20 img-18 img-22 img-22"
    "img-20 img-19 img-21 img-21"
    "img-35 img-36 img-37 img-38"
    "img-39 img-39 img-40 img-40"
    
    ;
}

/* ---------------------- End Tats Gallery ---------------------- */

/* ---------------------- Design Gallery ---------------------- */

.design-gallery {
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.design-gallery a {
  height: 70rem;
}

.design-gallery a {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 25rem;
  box-shadow: 0rem 0px 6rem 0.8rem rgba(0, 0, 0, 0.7);
}

.design-modal .modal-content .arrow-icon svg {
  color: black;
}

.load-more-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 4rem;
}

.load-more-container button {
  background-color: rgb(0, 0, 0, 1);
  color: #fff; /* White text */
  border-radius: 2rem;
  border: 2px solid white;
  padding: 0.5rem 4rem;
  transition: background-color 0.3s ease, padding 0.3s ease;
  box-shadow: 0.2rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.7);
}

.load-more-container button:hover {
  background-color: rgb(0, 0, 0, 0.9);
  padding: 1rem 5.5rem;
  cursor: pointer;
}

.load-more-text {
  display: flex;
  cursor: pointer;
  font-size: 3rem;

  justify-content: center;
}

.load-more-dots {
  display: flex;
  align-items: center;

  padding: 10px 20px;

  font-size: 8rem;
  cursor: pointer;
}

.load-more-button:focus {
  outline: none;
}

/* ---------------------- End Design Gallery ---------------------- */

/* ---------------------- Modal ---------------------- */

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-content {
  display: flex; /* Use flexbox for centering */
  align-items: center;
  justify-content: center;

  position: relative;

  width: auto; /* Reset to auto for better flexibility */
  max-width: 90vw;
  height: auto; /* Reset to auto */
  max-height: 100vh;
  margin: auto; /* Center the modal-content inside the modal-overlay */
  padding: 0.2rem; /* keep this, fixes weird bug */

  aspect-ratio: 9/16;
}

.modal-content img {
  display: block;
  position: relative; /* No longer absolute */

  object-fit: contain;
}

.image-container {
  position: relative;
  display: inline-block;

  width: 100%;
}

.modal-content .hover-area-left {
  position: absolute;

  top: 0;
  left: 0;
  z-index: 1002;

  width: 50%;
  height: 100%;

  opacity: 1;
  cursor: pointer;
}

.modal-content .hover-area-right {
  position: absolute;

  top: 0;
  right: 0;
  z-index: 1002;

  width: 50%;
  height: 100%;

  opacity: 1;
  cursor: pointer;
}

.modal-content .swipe-icon {
  position: absolute;

  top: 50%;
  left: 0;
  z-index: 1004;

  width: 15%; /* Adjust as per your design */
  height: auto; /* Maintain aspect ratio */
  color: white;

  opacity: 0;
  transition: opacity 0.5s;
}

.modal-content .arrow-icon svg {
  position: absolute;

  top: 50%;
  z-index: 1001;
  transform: translateY(-50%); /* Center svg vertically */

  width: 25%;

  font-size: 1rem;
  color: white;

  opacity: 0.2;
  transition: opacity 0.5s;
}

.modal-content .hover-area-left svg {
  left: 0;
}

.modal-content .hover-area-right svg {
  right: 0;
}

.modal-content .hover-area-left:hover svg {
  opacity: 1;
}

.modal-content .hover-area-right:hover svg {
  opacity: 1;
}

/* ---------------------- End Modal ---------------------- */

/* ---------------------- Nav Stuff ---------------------- */
nav {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* padding-bottom: 6rem; */
}

nav button {
  padding: 0;
  background: none;
}

nav button:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.nav-btn {
  display: none;
  visibility: hidden;
}

nav span {
  color: var(--cool-red);
}

nav ul {
  margin-top: 2rem;
  /* flex-grow: 1; */
}

nav ul li {
  display: inline-block;

  padding: 0rem 2.5rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

nav ul li a {
  color: var(--text-theme);
  text-decoration: none;
  font-weight: bold;
}

nav ul li a:hover {
  color: var(--my-grey);
  transition: 0.3s;
  animation: text-pop-up-top 1s;
}

.flex-horizontal-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
}

/* .entire-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
} */

.logo {
  width: 17rem;
  position: absolute;
  top: 3rem;
  right: 8rem;
  /* margin-left: auto; */
}

.logo-no-nav {
  width: 23rem;
}



.book-img {
  position: fixed;
  bottom: 5%;
  left: 8rem;

  width: 12rem;

  transition: transform .5s ease-in-out, opacity .1s ease-in-out;
  opacity: 0;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.5));
}

.book-img-visible {
  opacity: 1; 
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.book-img:hover {
  opacity: 0.6;
  cursor: pointer;
  transform: scale(1.1);
}


.title-img-header .flex-horizontal-container {
  justify-content: flex-start;
}

/* ---------------------- End Nav ---------------------- */

/* ---------------------- Big Logo ---------------------- */

.logo-big-container {
  display: flex;
  justify-content: center; /* Centers horizontally in the flex container */
  align-items: center; /* Centers vertically in the flex container */

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000; /* Ensure it's above other content */

  height: 100vh;
  width: 100vw;
}

.logo-big {
  position: absolute;

  width: 40%;
}

/* ---------------------- End Big Logo ---------------------- */

/* ---------------------- Footer ---------------------- */
.footer {
  /* bottom: 0; */
  margin-top: 2rem;
  padding-bottom: 1rem;
  /* background-image: linear-gradient(rgb(255, 255, 255, 0), rgb(0,0,0,.7));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
}

.footer a svg {
  color: var(--text-theme);
  width: 4rem;
  margin-top: 2rem;
  opacity: 0.8;
  transition: opacity 0.5s;
}

.footer a:hover svg {
  opacity: 1;
  cursor: pointer;
  /* transform: scale(1.1); */
  stroke: var(--cool-red);
  /* fill: red; */
  opacity: 0.5;
}

.footer .flex-horizontal-container {
  justify-content: center;
}

.footer .flex-horizontal-container a {
  margin: 0rem 1.3rem;
}

.footer h3 {
  margin-top: 2rem;
}

/* ---------------------- End Footer ---------------------- */

/* ---------------------- Swiper ---------------------- */
.swiper-container {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 0;
  overflow-x: clip;
  /* margin: -320px 0px;
  padding: 320px 0px; */
}

.swiper {
  /* overflow: visible; */

  left: 50%; /* Move the swiper to the right by 50% of its parent's width */
  transform: translateX(
    -50%
  ); /* Pull the swiper back to the left by 50% of its own width */

  width: 150%;
  height: auto;
  padding-bottom: 5rem;
}

.swiper-slide img {
  display: block; /* Remove any default inline spacing */
  object-position: center; /* Center the image within the slide */
}

.home-swiper img {
  max-height: 40rem;
  min-height: 40rem;
  width: 100%; /* TODO: ask andy about distortion */
  object-fit: cover;
}

.shadow-host {
  display: inline-block;
  box-shadow: 0.6rem 2rem 2rem 0.5rem rgba(0, 0, 0, 0.9);
  transform: scale(1);
  transition: transform 0.3s ease;
}

.home-swiper .swiper-slide {
  overflow: visible; /* Allow the shadow-host to overflow */
  margin: 20px 0px;
  padding: 10px 0px;
}

.home-swiper .swiper {
  width: 120%;
}

.home-swiper .swiper-slide-active .shadow-host {
  transform: scale(1.1);
}

/*  ---------------------- End Swiper ---------------------- */

/*  ---------------------- Contact ---------------------- */

.email-form-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

form {
  width: clamp(225px, 100%, 575px);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 20px;
}

form input {
  width: 100%;
  height: 4rem;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  box-shadow: 0.6rem 2rem 2rem 0.5rem rgba(0, 0, 0, 0.7);
  animation: slide-up-input 2s;
}

form input:focus {
  border: 2px solid rgba(0, 206, 158, 1);
}

form textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

form textarea:focus {
  border: 2px solid rgba(0, 206, 158, 1);
}

form label {
  margin-top: 1rem;
  animation: slide-up-label 2s;
}

form input[type="submit"] {
  margin-top: 2rem;
  cursor: pointer;
  background: rgb(249, 105, 14);
  color: white;
  border: none;
}

form .required {
  color: red;
}

form .submit-button {
  width: 100%;
  align-self: center;
  font-size: 2rem;
  animation: slide-up-button 2s;
}

form .submit-success {
  color: rgb(21, 255, 0);
}

form svg {
  width: 10%;
}

/*  ---------------------- End Contact ---------------------- */

/* ? ---------------------------------------------------- 1000 px -------------------------------------------------------- */

@media screen and (max-width: 1000px) {
}

/* ? ---------------------------------------------------- 900 px -------------------------------------------------------- */

@media screen and (max-width: 900px) {
}

/* ? -------------------------------------------------------- 700 px ----------------------------------------------------- */
@media screen and (max-width: 700px) {
  /* ---------------------- Image  Gallery ---------------------- */

  .gallery-component-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .image-gallery {
    display: flex;
    flex-direction: column;
    grid-row-gap: 2.5rem;
    grid-column-gap: 2.5rem;

    width: 100%;
    max-width: 400px;
    padding: 0 2rem;
    grid-template-columns: 1fr;
    grid-template-areas: none;
  }

  /* ---------------------- End Image  Gallery ---------------------- */
  /* ---------------------- Tats Gallery ---------------------- */

  .tats-gallery a {
    min-height: 38rem;
    max-height: 38rem;
    /* box-shadow: 0rem 0px 3rem 0.8rem rgba(0, 0, 0, 0.7); */
    box-shadow: 0.6rem 1.2rem 2rem 1rem rgba(0, 0, 0, 0.9);
  }

  /* ---------------------- End Tats Gallery ---------------------- */

  /* ---------------------- Design Gallery ---------------------- */

  .design-gallery {
    max-width: 450px;
  }

  .design-gallery a {
    /* box-shadow: 0.5rem 0px 3rem 0.8rem rgba(0, 0, 0, 0.7); */
    /* box-shadow: 0rem 0px 3rem 0.8rem rgba(0, 0, 0, 0.7); */
    box-shadow: 0.6rem 1.2rem 2rem 1rem rgba(0, 0, 0, 0.9);
    min-height: 40rem;
    max-height: 25rem;
  }

  /* ---------------------- End Design Gallery ---------------------- */

  /* -------------------------- Nav -------------------------- */

  nav {
    margin-top: 6rem;
  }

  .nav-btn {
    display: inline-block;

    position: relative;
    margin-left: -88%;

    visibility: visible;
  }

  .nav-btn svg {
    position: relative;

    width: 6rem;
    background: none;

    color: var(--text-theme);
  }

  nav ul {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    position: fixed;
    top: 0;
    left: -40rem; /* hides the navbar initially by placing it off the screen */
    bottom: 0;
    z-index: 10;

    width: 22rem; /* Adjust according to your design */
    margin-top: 0rem;
    padding-top: 7rem;
    background: black;
    border-right: black solid 2px;
    border-top: black solid 2px;

    text-align: left;

    transition: left 1s;
    box-shadow: 0.5rem 0px 3rem 0.8rem rgba(0, 0, 0, 0.7);
  }

  .side-nav ul {
    left: 0;
  }

  nav ul {
    padding-top: 7rem;
  }

  nav ul li {
    display: block;

    padding: 3rem 0px 0px 3rem;

    font-size: 2rem;
  }

  .nav-close-btn {
    position: absolute;
    right: 10%;
    top: 3%;
  }

  .logo {
    position: absolute;
    top: -4rem;
    right: 1rem;

    width: 14rem;
  }

  .book-img {
    position: fixed;
    bottom: 2%;
    left: 0.5rem;

    width: 10rem;
  }

  .book-img:hover {
    opacity: .6;
    cursor: pointer;
    transform: scale(1.1);
  }



  /* -------------------------- End Nav -------------------------- */

  /* -------------------------- Logo Big -------------------------- */

  /* -------------------------- End Logo Big -------------------------- */

  /* -------------------------- Modal Content -------------------------- */

  .modal-content {
    min-width: 90%;
  }

  .modal-content img {
    display: block;
    position: relative; /* No longer absolute */
    object-fit: contain;

    max-width: 100%; /* Allow the image to be fully contained within its parent */
    max-height: 100%;
  }

  .modal-content .swiper {
    width: 180%;

    transform: translate(-50%, 10%);
  }

  /* -------------------------- End Modal Content -------------------------- */

  /* -------------------------- Swiper -------------------------- */
  .swiper-slide {
    position: relative;

    padding-top: 0rem;
    background-color: rgb(0, 0, 0, 0);

    /* box-shadow: 2rem 2rem 4rem 2rem rgba(0, 0, 0, 0.7); */
  }

  .swiper-slide img {
    display: block; /* Remove any default inline spacing */
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the area of the slide */
    object-position: center; /* Center the image within the slide */
  }

  .home-swiper img {
    max-height: 35rem;
    min-height: 35rem;
    object-fit: cover;
  }

  .home-swiper .swiper {
    width: 280%;
  }

  .modal-swiper .modal-content {
    width: 200%;
  }

  .modal-swiper .image-container {
    width: 170%;
  }

  /* End Swiper */

  .footer {
    padding-bottom: 4rem;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 600px) {
  /* form {
    width: 275px;
  } */

  form {
    font-size: 2rem;
  }

  form input {
    font-size: 2rem;
  }

  form textarea {
    font-size: 2rem;
  }

  .home-swiper img {
    /* max-height: 35rem; */
    min-height: 30rem;
    object-fit: cover;
  }

  .home-swiper .swiper {
    width: 280%;
  }
}
