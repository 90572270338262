.show-swipe {
  animation: appear-and-swipe 4s forwards;
  animation-delay: 1s; /* Delay the start of the animation */
}

.fade-out {
  animation: fade-out-gradual 4s;
  animation-fill-mode: forwards; /* doesn't revert back to previous styles*/
  animation-delay: 1s;
}

.bounce-in-top {
  animation: bounce-in-top 2.5s;
}

.track-in {
  animation: tracking-in-contract-bck 2s;
  animation-delay: 0s;
}

.slide-in-ecliptic {
  animation: slide-in-elliptic-top-fwd 2s;
  animation-delay: 0s;
}

.roll-in-left {
  animation: roll-in-left 2s;
  animation-delay: 0s;
}

.roll-in-right {
  animation: roll-in-right 2s;
  animation-delay: 0s;
}

.roll-in-right-slow {
  animation: roll-in-right 3s;
  animation-delay: 0s;
}

.swing-in-top-fwd {
  animation: swing-in-top-fwd 4s;
  animation-delay: 0s;
}

.tilt-in-tr {
  animation: tilt-in-tr 2s;
  animation-delay: 0s;
}

.animation-delay {
  animation: animation-delay 4s;
  animation-delay: 0s;
}

.shake-bottom {
  animation: shake-bottom 1s;
  animation-delay: 3s;
}

.roll-in-right-shake-bottom {
  animation: roll-in-right 3s, shake-bottom 1s;
  animation-delay: 0s, 4s;
}

.slide-in-shake-bottom {
  animation: slide-in-elliptic-top-fwd 2s, shake-bottom 1s, shake-bottom 1s, shake-bottom 1s, shake-bottom 1s;
  animation-delay: 0s, 4s, 60s, 180s, 340s;
}

.just-shake-bottom {
  animation: shake-bottom 1s, shake-bottom 1s, shake-bottom 1s, shake-bottom 1s;
  animation-delay: 4s, 60s, 180s, 340s;
}

.jello-horizontal {
  animation: jello-horizontal 2s;
  animation-delay: 0s;
}

.blink-in {
  animation: blink-1 2s;
  animation-delay: 0s;
}

.blink-animation {
  animation-name: blink-1;
  animation-duration: 1s; /* Duration of one full cycle of the animation */
  animation-iteration-count: 1; /* Animation will repeat indefinitely */
  animation-timing-function: ease-in-out; /* Keeps the pacing of the animation consistent */
}

.flip-in-hor-top {
  -webkit-animation: flip-in-hor-top 2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: flip-in-hor-top 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.flip-in-hor-top-reg {
  animation: flip-in-hor-top 3s;
}

.slide-up-grid {
  animation: slide-up-grid 2s;
}

.text-shadow-pop-bottom {
  -webkit-animation: text-shadow-pop-bottom 2s both;
  animation: text-shadow-pop-bottom 2s both;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.slide-in-blurred-bottom {
  -webkit-animation: text-shadow-pop-bottom 2s both;
  animation: text-shadow-pop-bottom 2s both;
  /* animation-delay: 2s; */
  animation-fill-mode: forwards;
}

@keyframes animation-delay {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes appear-and-swipe {
  0% {
    opacity: 0; /* Start with the icon invisible */
    transform: translateX(70vw);
  }
  /* Adjust the following percentages as needed for your animation */
  20% {
    opacity: 1; /* Make the icon visible */
  }
  50% {
    transform: translateX(0);
  }
  80% {
    opacity: 0.6; /* Keep the icon visible during the animation */
  }
  100% {
    transform: translateX(70vw);
    opacity: 0; /* Optionally make the icon invisible again at the end */
  }
}

@keyframes slide-left {
  from {
    transform: translateX(60vw);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-left-and-back {
  0% {
    transform: translateX(70vw); /* Start from the original position */
  }
  50% {
    transform: translateX(0); /* Move to the right */
  }
  100% {
    transform: translateX(70vw); /* Return to the original position */
  }
}

@keyframes fade-out-gradual {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.2;
  }
  10% {
    opacity: 0.8;
  }
  15% {
    opacity: 1;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0; /* Return to the original position */
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0; /* Return to the original position */
  }
}

@keyframes streak-line {
  0%,
  100% {
    transform: translateX(0) scaleX(0); /* Start and end hidden */
  }
  25%,
  75% {
    transform: translateX(-60vw) scaleX(1); /* Fully visible and shifted left */
  }
}

@keyframes bounce-in-top {
  0%,
  20% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  44% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

@keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
    transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes roll-in-right {
  0% {
    -webkit-transform: translateX(800px) rotate(540deg);
    transform: translateX(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-right {
  0% {
    -webkit-transform: translateX(800px) rotate(540deg);
    transform: translateX(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes tilt-in-tr {
  0% {
    -webkit-transform: rotateY(-35deg) rotateX(20deg) translate(250px, -250px)
      skew(-12deg, -15deg);
    transform: rotateY(-35deg) rotateX(20deg) translate(250px, -250px)
      skew(-12deg, -15deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes slide-up-label {
  from {
    opacity: 0.2;
    margin-top: 200px;
  }

  to {
    opacity: 1;
    margin-top: 1rem;
  }
}

@keyframes slide-up-input {
  from {
    opacity: 0.2;
    margin-top: 200px;
  }

  to {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes slide-up-button {
  from {
    opacity: 0.2;
    margin-top: 200px;
  }

  to {
    opacity: 1;
    margin-top: 2rem;
  }
}

@keyframes slide-up-grid {
  from {
    opacity: 0.2;
    margin-top: 800px;
    grid-row-gap: 800px;
  }

  to {
    opacity: 1;
    grid-row-gap: 1.5rem;
  }
}

@media screen and (max-width: 700px) {
  @keyframes slide-up-grid {
    from {
      opacity: 0.2;
      margin-top: 600px;
      grid-row-gap: 600px;
    }

    to {
      opacity: 1;
      grid-row-gap: 2.5rem;
    }
  }
}

@-webkit-keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes text-shadow-pop-bottom {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555,
      0 0 #555555, 0 0 #555555, 0 0 #555555;
    box-shadow: 0 0 0 10px #555555;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    /* text-shadow: 0 1px #555555, 0 2px #555555, 0 3px #555555, 0 4px #555555,
      0 5px #555555, 0 6px #555555, 0 7px #555555, 0 8px #555555; */
    text-shadow: 0 1px 2px #555555, 0 2px 3px #555555, 0 3px 4px #555555,
      0 4px 5px #555555, 0 5px 6px #555555, 0 6px 7px #555555, 0 7px 8px #555555,
      0 8px 9px #555555;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5), 0 2px 2px rgba(0, 0, 0, 0.5),
      0 3px 3px rgba(0, 0, 0, 0.5), 0 4px 4px rgba(0, 0, 0, 0.5),
      0 5px 5px rgba(0, 0, 0, 0.5), 0 6px 6px rgba(0, 0, 0, 0.5),
      0 7px 7px rgba(0, 0, 0, 0.5), 0 8px 8px rgba(0, 0, 0, 0.5);
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    opacity: 1;
  }
}
@keyframes text-shadow-pop-bottom {
  0% {
    /* text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555,
      0 0 #555555, 0 0 #555555, 0 0 #555555;
    box-shadow: 0 0 0 10px #555555; */
    /* filter: drop-shadow(0 0px 0px rgba(0, 0, 0, 0));
    -webkit-transform: translateY(0);
    transform: translateY(0); */
    opacity: 0;
  }
  50% {
    /* text-shadow: 0 1px #555555, 0 2px #555555, 0 3px #555555, 0 4px #555555,
      0 5px #555555, 0 6px #555555, 0 7px #555555, 0 20px #555555;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5), 0 2px 2px rgba(0, 0, 0, 0.5),
      0 3px 3px rgba(0, 0, 0, 0.5), 0 4px 4px rgba(0, 0, 0, 0.5),
      0 5px 5px rgba(0, 0, 0, 0.5), 0 6px 6px rgba(0, 0, 0, 0.5),
      0 7px 7px rgba(0, 0, 0, 0.5), 0 8px 8px rgba(0, 0, 0, 0.5); */
    /* filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.5));
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px); */
    opacity: 1;
  }

  100% {

  }
}

@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
