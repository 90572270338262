img {
  max-width: 100%;
}

/* -------------------------- IMAGES -------------------------- */
.image-gallery .img-1 {
  grid-area: img-1;
}
  
.image-gallery .img-2 {
  grid-area: img-2;
}

.image-gallery .img-3 {
  grid-area: img-3;
}

.image-gallery .img-4 {
  grid-area: img-4;
}

.image-gallery .img-5 {
  grid-area: img-5;
}

.image-gallery .img-6 {
  grid-area: img-6;
}

.image-gallery .img-7 {
  grid-area: img-7;
}

.image-gallery .img-8 {
  grid-area: img-8;
}

.image-gallery .img-9 {
  grid-area: img-9;
}

.image-gallery .img-10 {
  grid-area: img-10;
}

.image-gallery .img-11 {
  grid-area: img-11;
}

.image-gallery .img-12 {
  grid-area: img-12;
}

.image-gallery .img-13 {
  grid-area: img-13;
}
.image-gallery .img-14 {
  grid-area: img-14;
}
.image-gallery .img-15 {
  grid-area: img-15;
}
.image-gallery .img-16 {
  grid-area: img-16;
}
.image-gallery .img-17 {
  grid-area: img-17;
}
.image-gallery .img-18 {
  grid-area: img-18;
}
.image-gallery .img-19 {
  grid-area: img-19;
}
.image-gallery .img-20 {
  grid-area: img-20;
}

.image-gallery .img-21 {
  grid-area: img-21;
}

.image-gallery .img-22 {
  grid-area: img-22;
}

.image-gallery .img-23 {
  grid-area: img-23;
}

.image-gallery .img-24 {
  grid-area: img-24;
}

.image-gallery .img-25 {
  grid-area: img-25;
  background-size: 100%;
  background-position: 48% 51%;
}

.image-gallery .img-26 {
  grid-area: img-26;
}

.image-gallery .img-27 {
  grid-area: img-27;
}

.image-gallery .img-27 img {
  height: 110%;
  object-position: 50% 80%;
}

.image-gallery .img-28 {
  grid-area: img-28;
}

.image-gallery .img-29 {
  grid-area: img-29;
}

.image-gallery .img-29 img{
  object-position: 80% 50%;
}

.image-gallery .img-30 {
  grid-area: img-30;
}

.image-gallery .img-31 {
  grid-area: img-31;
}

.image-gallery .img-32 {
  grid-area: img-32;
}

.image-gallery .img-33 {
  grid-area: img-33;
}

.image-gallery .img-34 {
  grid-area: img-34;
}
.image-gallery .img-35 {
  grid-area: img-35;
}
.image-gallery .img-36 {
  grid-area: img-36;
}
.image-gallery .img-37 {
  grid-area: img-37;
}
.image-gallery .img-38 {
  grid-area: img-38;
}

.image-gallery .img-39 {
  grid-area: img-39;
}

.image-gallery .img-40 {
  grid-area: img-40;
}




@media screen and (min-width: 700px) {
  .image-gallery .img-1,
  .img-6,
  .img-9,
  .img-14,
  .img-18,
  .img-20,
  .img-28,
  .img-23,
  .img-27,
  .img-2 {
    min-height: 51.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .swiper {
    width: 170%;
  }
}

@media screen and (max-width: 900px) and (min-width: 700px) {
  /* here'e one of your issues bud */
  /* .image-gallery .img-1, .img-2, .img-6, .img-8, .img-20,
  .img-14, .img-18 {
    min-height: 41.5rem;
  } */

  /* should this be for more than just image-gallery? */
  /* .image-gallery .img-1 {
    background-size: 160%;
    background-position: center 60%;
  }

  .image-gallery .img-4 {
    background-size: 180%;
    background-position: center 60%;
  }

  .image-gallery .img-5 {
    background-size: 100%;
    background-position: center 60%;
  }

  .image-gallery .img-7 {
    background-size: 170%;
    background-position: 55% 50%;
  }

  .image-gallery .img-8 {
    background-size: 150%;
    background-position: 45% 72%;
  }

  .image-gallery .img-9 {
    background-size: 250%;
    background-position: 55% 57%;
  }

  .image-gallery .img-16 {
    background-size: 230%;
    background-position: 55% 61%;
  }

  .image-gallery .img-18 {
    background-size: 150%;
    background-position: 47% 75%;
  }

  .image-gallery .img-19 {
    background-size: 300%;
    background-position: 47% 52%;
  }

  .image-gallery .img-21 {
    background-size: 250%;
    background-position: 47% 67%;
  } */
}

@media screen and (max-width: 700px) {
  .image-gallery .img-1{
    background-size: 140%;
    background-position: center 60%;
  }
  .image-gallery .img-2{
    background-size: 120%;
    background-position: center 80%;
  }
  .image-gallery .img-6{
    background-size: 100%;
    background-position: center 50%;
  }

  .image-gallery .img-9{
    background-size: 170%;
    background-position: center 55%;
  }

  .image-gallery .img-12{
    background-size: 170%;
    background-position: center 55%;
  }

  .image-gallery .img-13{
    background-size: 170%;
    background-position: center 55%;
  }

  .image-gallery .img-14{
    background-size: 170%;
    background-position: center 55%;
  }

  .image-gallery .img-15{
    background-size: 100%;
    background-position: center 55%;
  }

  .image-gallery .img-16{
    background-size: 200%;
    background-position: center 55%;
  }
  .image-gallery .img-18{
    background-size: 150%;
    background-position: 40% 60%;
  }

  .image-gallery .img-20{
    background-size: 130%;
    background-position: 50% 50%;
  }

  .image-gallery .img-22{
    background-size: 110%;
    background-position: 40% 50%;
  }

  .image-gallery .img-23{
    background-size: 110%;
    background-position: 40% 50%;
  }

  .image-gallery .img-27{
    background-size: 130%;
    background-position: 45% 55%;
  }

  .image-gallery .img-28{
    background-size: 130%;
    background-position: 45% 68%;
  }

}
