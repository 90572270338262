/*
  TODO: Try minimizing menu icon for phone
  TODO: organize css
    - change px to rem, watch video first
    - remove unused css
    - reorganize tats to another file
    - organize within the classes
  ! Example error
  * bolded comment
  ? I have a question about this
  // Ignore this
  @param hat This is a param - need to add this
*/

/*
  - display stuff
    - display flex
    - flex-basis, flex direction
    - grid-template-columns

  - positioning stuff

  - box model stuff
    -width, height, background, borders, padding, margins

  - typography

  - manipulations
    - blur, tranform, filter

  - micellaneuos
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --cool-red: #ea1508;
  --cool-red-light: #8c302b;
  --secondary-color: #b0e4e4;
  --off-white: #f0f0f0;
  --my-grey: rgb(164, 149, 149);
  --text-theme: white;
}

.no-select {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
}

@font-face {
  font-family: "JMHTypewriter";
  src: url("../fonts/jmh_typewriter-webfont.woff2") format("woff2"); /* adjust path and format accordingly */src: url("../fonts/jmh_typewriter-webfont.woff") format("woff"); /* adjust path and format accordingly */
  src: url("../fonts/jmh_typewriter-webfont.woff") format("woff"); /* adjust path and format accordingly */src: url("../fonts/jmh_typewriter-webfont.woff") format("woff"); /* adjust path and format accordingly */
  font-display: swap;
}

html {
  font-family: "JMHTypewriter", Times, Georgia, Cochin,  Times,
    "Times New Roman", serif;
  font-size: 10px;
  /* Can use font-display to only display a font when it's downloaded"
  /* background: var(--off-white); */
  background: white;
  color: var(--text-theme);
  text-align: center;
  /* overflow-x: hidden; */
  min-width: 1050px;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: scroll;

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* .pages {
  overflow-x: hidden;
} */

.bg {
  position: fixed;
  background-size: cover;
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-attachment: fixed;
  background-position: center center;/* Optional: Centers the image */
  z-index: -10;
}



.container {
  max-width: 120rem;
  margin: auto;
  padding: 2rem 6rem;
  overflow: visible;
}

header {
  position: relative;
  font-size: 2.4rem;
  z-index: 1;
  margin-bottom: 2rem;
}

a {
  text-decoration: none;
  color: var(--text-theme);
}

a:hover {
  color: var(--my-grey);
}

h1 {
  font-size: 7.5rem;
}

h2 {
  font-size: 5rem;
}

h3 {
  font-size: 2.6rem;
}

h4 {
  font-size: 2.0rem;
}

.spacing {
  margin-top: 5rem;
}

.spacing-bottom {
  margin-bottom: 3rem;
}

.text-shadow {
  text-shadow: 1px 1.5px 2px black;
}

button {
  border: none;

  font-size: 2rem;

  transition: 0.4s;

  border-radius: 3rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button:hover {
  /* transform: scale(1.3); */
  cursor: pointer;
}

/* .main-background {
  display: flex;
  position: relative;
  align-items: center;

  height: 80vh;
} */

.video-background {
  position: fixed; /* Fixed position to cover the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 103vh;
  z-index: -1; /* Ensure it stays behind other content */
}

.video-background video {
  position: relative;
  width: 100%;
  height: 100%;
  /* transform: translateX(-50%); */
  object-fit: cover;
  z-index: -2;
}

.video-background img {
  position: relative;
  width: 100%;
  height: 100%;
  /* transform: translateX(-50%); */
  object-fit: cover;
  /* z-index: 2; */
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000; /* Initially above everything to capture interaction */
}

.video-overlay-hidden {
  display: none;
}



.picture-background {
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  height: 100vh;
  width: 100%;
  z-index: -2; /* Ensure it stays behind video */
}

.picture-background img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-scroll {
  overflow: hidden;
}

.vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.prevent-default {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */

}

@media screen and (max-width: 700px) {
  html {
    min-width: 300px;
  }
  
  .container {
    padding: 0rem 4rem;
  }

  h1 {
    font-size: 5rem;
  }
  
  header {
    margin-bottom: 6rem;
  }

}

/*
  Some resources:
    - Improve media queires:
        https://bradfrost.com/blog/post/7-habits-of-highly-effective-media-queries/
*/
